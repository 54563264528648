<template>
    <div class="doctorHome" :style="doctorHome">
        <div class="myHomeTop">
            <van-image width="100%" :src="myHomeTop">
                <template v-slot:error>加载失败</template>
            </van-image>
        </div>
        <div class="myHomePopularize">
            <div class="popularizeTitle modTitle">
                <div class="modIcon">
                    <van-image width="15" :src="require('@/assets/images/crown.png')"></van-image>
                </div>
                <div class="modText">会员权益</div>
                <div class="modSubheading">免费服务 · 专家诊疗 · 康复沙龙 · 专享优惠</div>
            </div>
            <van-swipe :autoplay="3000">
                <van-swipe-item v-for="(image, index) in images" :key="index">
                    <img :src="image" width="100%" />
                </van-swipe-item>
            </van-swipe>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            myHomeTop: require('@/assets/images/my-home-top.png'),
            images: [
                require('@/assets/images/my-home-popularize.png'),
                require('@/assets/images/my-home-popularize.png'),
            ],
            doctorHome: {
                backgroundImage: 'url(' + require('@/assets/images/myBg.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                height: '100%',
            },
        };
    },
    methods: {},
};
</script>
<style scoped>
.myHomeTop {
    padding: 10px;
}
.myHomePopularize {
    height: calc(100% - 270px);
    background: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin: 0 10px 10px;
    overflow-y: auto;
}
</style>
